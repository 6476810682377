<template>
  <span>
    <div class="flix-well" v-if="type == 'two'">
      <div class="flix-text-center">
        <img v-if="status === 'inactive'" class="open" src="/assets/img/signs/open.svg" />
        <img v-if="status === 'active'" class="close" src="/assets/img/signs/close.svg" />
      </div>
      <div class="flix-row" :title="getStatusTitle()">
        <div class="flix-col-xs-5 flix-text-center flix-form-group">
          <small class="flix-html-small">{{ $t("constants.months")[date[0].getMonth()+1] }} {{ date[0].getFullYear() }}</small>
          <div class="flix-html-h1">{{ $getNullsBefore(date[0].getDate()) }}</div>
          <flixIcon :id="'clock'" /> {{ $getNullsBefore(date[0].getHours()) }}:{{ $getNullsBefore(date[0].getMinutes()) }}
        </div>
        <div class="flix-col-xs-2 flix-text-center flix-form-group" style="padding-top: 20px; font-size: 28pt">
          –
        </div>
        <div class="flix-col-xs-5 flix-text-center flix-form-group">
          <small class="flix-html-small">{{ $t("constants.months")[date[1].getMonth()+1] }} {{ date[1].getFullYear() }}</small>
          <div class="flix-html-h1">{{ $getNullsBefore(date[1].getDate()) }}</div>
          <flixIcon :id="'clock'" /> {{ $getNullsBefore(date[1].getHours()) }}:{{ $getNullsBefore(date[1].getMinutes()) }}
        </div>
        <div class="flix-col-xs-12 flix-text-center">
          <div class="flix-text-info">
            <small class="flix-text-small">{{ title }}</small>
          </div>
        </div>
        <div class="flix-col-xs-12 flix-text-center">
          <small v-if="status === 'active'" class="flix-text-small flix-text-danger">- {{ $t('message.bookingsNotAllowed') }} -</small>
          <small v-if="status === 'inactive'" class="flix-text-small flix-text-info">- {{ $t('message.bookingsAllowed') }} -</small>
        </div>
        <div class="flix-col-xs-12 flix-text-center">
          <span v-if="calendar !== '*'"><hr>{{ $t('message.validFor', {name: ': '+'"'+$store.getters.assistentsByID[calendar].title+'"'}) }}</span>
          <span v-else><hr>{{ $t('message.validFor', {name: ': '+$t('message.allCalendar')}) }}</span>
        </div>
      </div>
    </div>

    <div class="flix-well" v-if="type == 'one'">
      <div class="flix-text-center">
        <img v-if="status === 'inactive'" class="open" src="/assets/img/signs/open.svg" />
        <img v-if="status === 'active'" class="close" src="/assets/img/signs/close.svg" />
      </div>
        <div class="flix-row" :title="getStatusTitle()">
          <div class="flix-col-xs-12 flix-text-center flix-form-group">
            <small class="flix-html-small">{{ $t("constants.months")[date[0].getMonth()+1] }} {{ date[0].getFullYear() }}</small>
            <div class="flix-html-h1">{{ $getNullsBefore(date[0].getDate()) }}</div>
            <flixIcon :id="'clock'" /> {{ dateString[0] }}
          </div>
          <div class="flix-col-xs-12 flix-text-center">
            <div class="flix-text-info">
              <small class="flix-text-small">{{ title }}</small>
            </div>
          </div>
          <div class="flix-col-xs-12 flix-text-center">
            <small v-if="status === 'active'" class="flix-text-small flix-text-danger">- {{ $t('message.bookingsNotAllowed') }} -</small>
            <small v-if="status === 'inactive'" class="flix-text-small flix-text-info">- {{ $t('message.bookingsAllowed') }} -</small>
          </div>
          <div class="flix-col-xs-12 flix-text-center">
            <span v-if="calendar !== '*'"><hr>{{ $t('message.validFor', {name: ': '+'"'+$store.getters.assistentsByID[calendar].title+'"'}) }}</span>
            <span v-else><hr>{{ $t('message.validFor', {name: ': '+$t('message.allCalendar')}) }}</span>
          </div>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  components: {
  },
  props: { date: Array, title: String, status: String, calendar: String },
  data () {
    return {
      type: '',
      dateString: []
    }
  },
  methods: {
    getStatusTitle () {
      if (this.status === 'active') {
        return this.$t('message.holidayActiveText')
      }

      return this.$t('message.holidayInactiveText')
    },
    checkDate () {
      if (this.date[0].getTime() === this.date[1].getTime()) {
        this.type = 'one'
        this.dateString[0] = this.$getNullsBefore(this.date[0].getHours()) + ':' + this.$getNullsBefore(this.date[0].getMinutes())
        return false
      }
      if (this.date[0].getFullYear() === this.date[1].getFullYear() && this.date[0].getMonth() === this.date[1].getMonth() && this.date[0].getDate() === this.date[1].getDate()) {
        this.type = 'one'
        this.dateString[0] = this.$getNullsBefore(this.date[0].getHours()) + ':' + this.$getNullsBefore(this.date[0].getMinutes()) + '-' + this.$getNullsBefore(this.date[1].getHours()) + ':' + this.$getNullsBefore(this.date[1].getMinutes())
        return false
      }
      this.type = 'two'
    }
  },
  mounted () {
    this.checkDate()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-well
    display: inline-block
    margin-bottom: 0
    margin-right: 10px

  .flix-html-h1
    margin: 0
  img
    width: 60px
    &.open
      transform: rotate(10deg)
    &.close
      transform: rotate(-10deg)
</style>
